import React, { useEffect, useState } from 'react';
import { bootstrapCameraKit, createMediaStreamSource, Transform2D } from "ck-postar";
import SpinningImage from '../images/eye.png';
import attributionlogo from '../images/attribution.png'

const CameraComponent = ({lensIndex, cameraStyle, borderColorIn}) => {
  const lensNames = ['ikigai', 'ibasho', 'yutori', 'wabisabi'];
  // lensNames.reverse();
  const currentLens = lensIndex;

  if(currentLens > lensNames.length){
    console.log("Lens index out of range");
    return;
  }

  const [borderStyle, setBorderStyle] = useState('0px solid #fff');
  const [isLoaded, setIsLoaded] = useState(false);
  const [getSession, setSession] = useState(null);
  const [myLenses, setLenses] = useState(null);
  useEffect(() => {
    let session
    let myLens
    const initializeCamera = async () => {

      if(getSession != null){
        //update the lens
        session = getSession;
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: cameraStyle, width: 720, height: 1280 } });
        await session.applyLens(myLenses[currentLens]);
        // console.log("Lens changed to: " + currentLens);
        // console.log("view changed to: " + cameraStyle);
        let source;
        if(cameraStyle === 'environment'){
            source = createMediaStreamSource(stream);
        }
        else{
            source = createMediaStreamSource(stream, { transform: Transform2D.MirrorX });
        }

        // Set the source to the session
        //await source.setRenderSize(500, 1000);
        await session.setSource(source);
        await source.setRenderSize(720, 1280);
        return;

      }

      else{
        setSession(session);
        //setLenses(lenses);
      }

      
      try {
        const apiToken = "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzExMDMzNzA5LCJzdWIiOiIxNGU0NzQ4Ny0xNDVlLTQwYTctODA2ZS0xMzllYzZiMDg2YjR-UFJPRFVDVElPTn4xYzczMDliOC05M2Q5LTQ3ZDgtYTEyYy1mMmNlZDk2YTIwYmEifQ.W_k3TEHGE8Ry3X1G7OJku-7qWGrWhHjJNg74PWCPSOY"
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("my-canvas");
        const session = await cameraKit.createSession({ liveRenderTarget: canvas });
        setSession(session);
        session.events.addEventListener('error', (event) => {
          if (event.detail.error.name === 'LensExecutionError') {
            console.log('The current Lens encountered an error and was removed.', event.detail.error);
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: cameraStyle } });
        let source;
        if(cameraStyle === 'environment'){
          source = createMediaStreamSource(stream);
      }
        else{
            source = createMediaStreamSource(stream, { transform: Transform2D.MirrorX });
        }
        await session.setSource(source);
        await source.setRenderSize(720, 1280);
        const lensToLoad = 'dfccd637-1cdb-493e-8268-1760a9a49250';
        // console.log(lensToLoad);
        const { lenses } = await cameraKit.lensRepository.loadLensGroups([
          lensToLoad
        ]);
        setLenses(lenses);
        // console.log(lensIndex);
        // console.log(lensNames.indexOf(lensIndex));
        console.log(lenses)
        await session.applyLens(lenses[lensNames.indexOf(lensIndex)]);
        canvas.focus();
        await session.play();
        //setBorderStyle(`20px solid ${borderColorIn}`);
        setIsLoaded(true);
        console.log("Lens rendering has started!");
      } catch (error) {
        console.error('Error initializing camera:', error);
      }
    };

    initializeCamera();

    // Cleanup function
    return () => {
      // Perform cleanup (e.g., stop the camera session) if needed
    };
  }, [lensIndex, cameraStyle]); // Empty dependency array ensures that the effect runs only once on mount



    
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {<canvas
          id="my-canvas"
          style={{ width: 'auto', height: '70vh' }}
          
        ></canvas>}
    </div>
  );
};

export default CameraComponent;
